import { lazy, Suspense, memo } from "react";

import Loading from "./Loading";
const LazyUserFavorites = memo(lazy(() => import("./FavoritesMembers")));
const LazyUserVisitedProfiles = memo(lazy(() => import("./VisitedMembersList")));
const LazyFilteredMembers = memo(lazy(() => import("./FilteredMembers")));

import { Tabs, Item, TabPanels, TabList, Text, ContextualHelp, Content, Heading } from "@adobe/react-spectrum";

import Star from "@spectrum-icons/workflow/PinOn";
import Visit from "@spectrum-icons/workflow/Visit";
import Demographic from "@spectrum-icons/workflow/Demographic";

function HomeTabs({ isSearching, setIsTabs, isTabs }) {
  return (
    <>
      <Tabs
        marginX="size-0"
        alignSelf="center"
        justifySelf="center"
        disallowEmptySelection="true"
        defaultSelectedKey="1"
        density="regular"
        aria-label="Les membres du Tramway sont tous ici"
        selectedKey={isTabs}
        onSelectionChange={setIsTabs}
      >
        <TabList>
          <Item key="1" textValue="Wayers">
            <Demographic size="S" aria-label="Les membres selon tes critères de recherche" />
            <Text UNSAFE_className="nospace">Wayers</Text>
          </Item>
          <Item key="2" textValue="Visites">
            <Visit size="S" aria-label="Visites" />
            <Text UNSAFE_className="nospace">Visites</Text>
          </Item>
          <Item marginStart="size-10" key="3" textValue="Favoris">
            <Star size="S" aria-label="Favoris" />
            <Text UNSAFE_className="nospace">Favoris</Text>
          </Item>
        </TabList>

        <TabPanels marginTop="size-200">
          <Item key="1" textValue="Résultat de ma recherche.">
            Résultat de ma recherche.{" "}
            <ContextualHelp variant="info">
              <Heading>
                Ton petit ecosystème<span title="Emoji : un bivouac, des étoiles">🏕️✨</span>
              </Heading>
              <Content>
                <Text>
                  Comment cela fonctionne-t-il ? Jetez un coup d&apos;œil ! Trouvez facilement des gens qui veulent vous
                  rencontrer. Voyez où se trouvent les autres membres et aidez-vous les uns les autres, que ce soit dans
                  les cercles, en partageant vos histoires ou en devenant amis... Et plus si affinités.
                </Text>
              </Content>
            </ContextualHelp>
            <Suspense fallback={<Loading />}>
              <LazyFilteredMembers isSearching={isSearching} />
            </Suspense>
          </Item>

          <Item key="2" textValue="Les dernières visites que tu as reçues.">
            Les dernières visites que tu as reçues.
            <Suspense fallback={<Loading />}>
              <LazyUserVisitedProfiles isSearching={isSearching} />
            </Suspense>
          </Item>

          <Item key="3" textValue="Tes membres favoris.">
            Tes membres favoris.
            <Suspense fallback={<Loading />}>
              <LazyUserFavorites isSearching={isSearching} />
            </Suspense>
          </Item>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default HomeTabs;
