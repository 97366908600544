import { useMemo, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserSession from "../contexts/UserSession";
import { BOTTOM_NAV_ITEMS } from "../constants/footer_items";

import { View, StatusLight, ActionGroup, Item, Text } from "@adobe/react-spectrum";
import { useUnreadCounts } from "../services/rpc_supabase/notifications";
import GetBottomNavIcon from "../config/GetBottomNavIcon";

const BottomNav = () => {
  const navigate = useNavigate();
  const { sessionRole } = useContext(UserSession);
  const { data = {}, error, isLoading } = useUnreadCounts();
  const { flash = 0, visit = 0, message = 0 } = data;

  const [loading, setLoading] = useState(isLoading);

  // Memoize bottom nav items
  const memoizedBottomNavItems = useMemo(() => BOTTOM_NAV_ITEMS, []);

  // Update loading state when isLoading changes
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handleBottomNavClick = (key) => {
    navigate(key);
  };

  const shouldShowNotification = (label) => {
    switch (label) {
      case "Wayers":
        return visit > 0;
      case "Courrier":
        return message > 0;
      case "Likes":
        return flash > 0;
      case "Cercles":
        return false;
      default:
        return false;
    }
  };

  return (
    <View backgroundColor="gray-50" zIndex="10" bottom="size-0" position="fixed" paddingY="size-150" width="100%">
      <ActionGroup
        zIndex="9"
        isDisabled={sessionRole !== "authenticated"}
        buttonLabelBehavior="hide"
        onAction={handleBottomNavClick}
        isQuiet
        isJustified
        orientation="horizontal"
        density="compact"
        isEmphasized
        aria-label="Navigation bas de page"
        overflowMode="collapse"
        defaultSelectedKeys={[memoizedBottomNavItems[0]?.label]}
        selectionMode="single"
        items={memoizedBottomNavItems}
      >
        {(item) => (
          <Item key={item.to} aria-label={`Naviguer vers : ${item.label}`}>
            <Text>{item.label}</Text>
            {GetBottomNavIcon(item.label)}

            <StatusLight
              role="status"
              aria-label={`Notification pour : ${item.label}`}
              isHidden={!shouldShowNotification(item.label)}
              isDisabled={loading || error}
              zIndex="-1"
              variant="info"
              margin="size-0"
              position="absolute"
              right={0}
            />
          </Item>
        )}
      </ActionGroup>
    </View>
  );
};

export default BottomNav;
