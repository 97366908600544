import { useLayoutEffect } from "react";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import UserListView from "../../components/MemberData/UserListView";
import ProfilTabs from "../../components/MemberData/ProfilTabs";
import { ProgressBar } from "@adobe/react-spectrum";

import { useAsyncProfiles } from "../../services/rpc_supabase/bucket";

export default function Profil() {
  const { secureList, loadProfiles } = useAsyncProfiles();

  useLayoutEffect(() => {
    loadProfiles({
      isOwnProfile: true,
      isLiked: false,
      isVisited: false,
      likedYou: false,
      isNotLiked: false,
      isSearching: false,
    });
  }, []);

  return (
    <PrivateLayout>
      {secureList.loadingState === "loading" ? (
        <ProgressBar
          height="size-700"
          size="L"
          labelPosition="side"
          minWidth="100%"
          label="Chargement"
          isIndeterminate
        />
      ) : (
        <UserListView isOwnProfile={true} secureList={secureList} />
      )}
      <ProfilTabs secureList={secureList} />
    </PrivateLayout>
  );
}
