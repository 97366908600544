import { useState, lazy, Suspense, memo } from "react";

import Loading from "../../components/Loading";
import HomeTabs from "../../components/HomeTabs";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import FullScreenMapBtn from "../../components/GeoCode/FullScreenMapBtn";

const LazySearchMembers = memo(lazy(() => import("../../components/MemberData/SearchMembersDialog")));
const LazyUserSwipeableView = memo(lazy(() => import("../../components/MemberData/UserSwipeableView")));

import Properties from "@spectrum-icons/workflow/Properties";
import MagicWand from "@spectrum-icons/workflow/MagicWand";
import Discover from "@spectrum-icons/workflow/Globe";
import Search from "@spectrum-icons/workflow/Search";

import { ActionButton, Flex, Text, DialogTrigger, ToggleButton, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

export default function Accueil() {
  let [isSearching, setIsSearching] = useState(false);
  let [isTabs, setIsTabs] = useState("");
  console.log(isSearching);

  return (
    <>
      <PrivateLayout
        width={{ base: "100%", L: "70%", M: "100%", S: "100%" }}
        borderWidth={{ base: "thin", L: "thin", M: "thin", S: "thin" }}
        borderColor="dark"
        borderRadius="medium"
        padding={{ base: "size-100", L: "size-250", M: "size-250", S: "size-250" }}
        height="size-5000"
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="size-100">
            {/* RECHERCHE INPUT */}

            <TooltipTrigger>
              <ToggleButton
                isSelected={isSearching}
                onChange={setIsSearching}
                aria-label={isSearching ? "Ma recherche" : "Tout les membres"}
              >
                {isSearching ? (
                  <>
                    <Search />
                    <Text>Ma recherche</Text>
                  </>
                ) : (
                  <>
                    <Discover />
                    <Text>Membres</Text>
                  </>
                )}
              </ToggleButton>
              <Tooltip>{isSearching ? "Ma recherche" : "Tout les membres"}</Tooltip>
            </TooltipTrigger>

            {/* MAP */}
            <FullScreenMapBtn />

            <DialogTrigger type="tray" isDismissable>
              <TooltipTrigger>
                <ActionButton aria-label="format carte">
                  <MagicWand />
                </ActionButton>
                <Tooltip>"format carte"</Tooltip>
              </TooltipTrigger>
              {(close) => (
                <Suspense fallback={<Loading />}>
                  <LazyUserSwipeableView isSearching={isSearching} close={close} />
                </Suspense>
              )}
            </DialogTrigger>
          </Flex>

          {/* RECHERCHE */}
          <DialogTrigger type="tray">
            <TooltipTrigger>
              <ActionButton
                isQuiet
                aria-label="Pramètres de recherche que nous dans ton compte personnel pour des recherches ultérieures."
                marginY="size-200"
              >
                <Properties />
              </ActionButton>
              <Tooltip>Préférences de recherche</Tooltip>
            </TooltipTrigger>
            {() => (
              <Suspense fallback={<Loading />}>
                <LazySearchMembers setIsSearching={setIsSearching} />
              </Suspense>
            )}
          </DialogTrigger>
        </Flex>

        <HomeTabs isSearching={isSearching} setIsTabs={setIsTabs} isTabs={isTabs} />
      </PrivateLayout>
    </>
  );
}
