import PropTypes from "prop-types";
import { useState, useContext, useCallback, useEffect } from "react";
import { Text, LabeledValue, ToggleButton, Flex, View } from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import Checkmark from "@spectrum-icons/workflow/Checkmark";
import FullScreenMapBtn from "../GeoCode/FullScreenMapBtn";
import { supabase } from "../../supabaseClient";
import UserSession from "../../contexts/UserSession";

const CardBody = ({ name, circleId, subscriberCount, isSelected }) => {
  const { session } = useContext(UserSession);

  // Initialize the states with the provided values
  const [isCircleSelected, setIsCircleSelected] = useState(false);
  const [isSub, setIsSub] = useState(0);

  // Synchronize local state with incoming props
  useEffect(() => {
    setIsCircleSelected(isSelected);
    setIsSub(subscriberCount);
  }, [isSelected, subscriberCount]);

  // Toggle subscription handler with proper state handling
  const toggleCircleSubscription = useCallback(async () => {
    const userId = session?.user?.id;
    if (!userId) return;

    // Optimistic state update
    const newIsCircleSelected = !isCircleSelected;
    const newIsSub = Math.max(0, newIsCircleSelected ? isSub + 1 : isSub - 1);

    setIsCircleSelected(newIsCircleSelected);
    setIsSub(newIsSub);

    try {
      const { error } = await supabase.rpc("toggle_circle_subscription", {
        p_profile_id: userId,
        p_circle_id: circleId,
      });

      // Revert state in case of an error
      if (error) {
        console.error("Error updating subscriptions:", error);
        setIsCircleSelected(!newIsCircleSelected);
        setIsSub(isSub); // Revert to previous subscriber count
      }
    } catch (err) {
      console.error("General error:", err);
      // Revert state on general error
      setIsCircleSelected(!newIsCircleSelected);
      setIsSub(isSub); // Revert to previous subscriber count
    }
  }, [session?.user?.id, circleId, isCircleSelected, isSub]);

  return (
    <View
      borderBottomColor="dark"
      borderBottomWidth="thin"
      borderBottomStartRadius="large"
      borderBottomEndRadius="large"
      borderTopStartRadius="xsmall"
      borderTopEndRadius="xsmall"
      colorVersion={6}
    >
      <Flex alignItems="center" minWidth="100%" marginY="size-50">
        <Flex direction="column" width="75%" marginX="size-100">
          <Text margin="size-0">{name}</Text>
          <LabeledValue label="Members" value={isSub} labelPosition="side" labelAlign="end" />
        </Flex>
        <Flex minWidth="25%" alignItems="center" gap="size-100" justifyContent="right" marginEnd="size-100">
          <FullScreenMapBtn circleId={circleId} />
          <ToggleButton
            isSelected={isCircleSelected} // Use the local state for toggle
            onChange={toggleCircleSubscription}
            aria-label={isCircleSelected ? "Leave this circle" : "Join this circle"}
          >
            {isCircleSelected ? <Checkmark /> : <Add />}
          </ToggleButton>
        </Flex>
      </Flex>
    </View>
  );
};

CardBody.propTypes = {
  name: PropTypes.string.isRequired,
  circleId: PropTypes.string.isRequired,
  subscriberCount: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

CardBody.displayName = "CardBody";
export default CardBody;
