import PropTypes from "prop-types";
import { BOTTOM_NAV } from "../constants/theme";

import Heart from "@spectrum-icons/workflow/Heart";
import Mailbox from "@spectrum-icons/workflow/Chat";
import Circle from "@spectrum-icons/workflow/Circle";
import GraphStreamRanked from "@spectrum-icons/workflow/GraphStreamRanked";

export default function GetFooterIcon(iconName) {
  switch (iconName) {
    case BOTTOM_NAV.membres:
      return <GraphStreamRanked />;
    case BOTTOM_NAV.cercles:
      return <Circle />;
    case BOTTOM_NAV.likedYou:
      return <Heart />;
    case BOTTOM_NAV.message:
      return <Mailbox />;
    default:
      return "";
  }
}

GetFooterIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
};
